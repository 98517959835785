
import { defineComponent } from 'vue'
import ModuleHeader from '@/components/commons/boxes/ModuleHeader.vue'
import CreateForm from '@/views/coderplus/components/CreateForm.vue'
export default defineComponent({
  components: {
    ModuleHeader,
    CreateForm
  },
  props: {},
  setup () {
    return {}
  }
})
