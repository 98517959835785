
import { computed, defineComponent, ref } from 'vue'
import FileUpload from 'primevue/fileupload'
import Divider from 'primevue/divider'
import { CoderplusAPI } from '@/api/coderplus.api'
import { Video, VideoCategory } from '@/models/coderplus/classes/Video'
import { useToast } from 'primevue/usetoast'
import { ToastLife, ToastSeverities, ToastSummaries } from '@/models/components/toast/enums'
export default defineComponent({
  components: {
    Divider,
    FileUpload
  },
  props: {},
  setup () {
    const toast = useToast()
    const imageName = ref<string>('')
    const title = ref<string>('')
    const author = ref<string>('')
    const position = ref<string>('')
    const company = ref<string>('')
    const hashtags = ref<string[]>([])
    const link = ref<string>('')
    const base64 = ref<string>('')
    const hashtagText = ref<string>('')
    const videoCreated = ref<Video>()
    const flag = ref<boolean>(false)
    const showCategory = ref<boolean>(false)
    const newCategory = ref<string>('')
    const categories = ref<VideoCategory[]>(
      [
        {
          name: 'Producto',
          key: 'product'
        },
        {
          name: 'Diseño',
          key: 'diseño'
        },
        {
          name: 'Desarrollo',
          key: 'desarrollo'
        },
        {
          name: 'Marketing',
          key: 'marketing'
        }
      ]
    )
    const selectedCategories = ref<VideoCategory[]>([])
    const disablePublish = computed(() => {
      if (
        title.value === '' ||
        author.value === '' ||
        hashtags.value.length === 0 ||
        base64.value === '' ||
        link.value === '' ||
        selectedCategories.value.length === 0
      ) {
        return true
      }
      return false
    })
    return {
      disablePublish,
      imageName,
      link,
      title,
      author,
      position,
      company,
      hashtags,
      categories,
      hashtagText,
      showCategory,
      selectedCategories,
      newCategory,
      clickCheck: (selectedCheck: VideoCategory) => {
        if (!selectedCategories.value.find(element => element.key === selectedCheck.key)) {
          selectedCategories.value = []
        }
        selectedCategories.value.push(selectedCheck)
      },
      addTag: (event: any) => {
        event.preventDefault()
        const val = event.target.value.trim()
        if (val === '') {
          toast.add({
            summary: ToastSummaries.Error,
            detail: 'No puede agregar un tag vacío',
            severity: ToastSeverities.Error,
            life: ToastLife.Default
          })
        } else if (val.charAt(0) !== '#') {
          toast.add({
            summary: ToastSummaries.Error,
            detail: 'Debe comenzar el tag por #',
            severity: ToastSeverities.Error,
            life: ToastLife.Default
          })
        } else {
          hashtags.value.push(val)
          event.target.value = ''
        }
      },
      removeTag: (index: number) => {
        hashtags.value.splice(index, 1)
      },
      onUpload: (event: any) => {
        imageName.value = event.files[0].name
        const reader = new FileReader()
        reader.readAsDataURL(event.files[0])
        reader.onload = () => {
          if (reader.result !== null) {
            base64.value = reader.result.toString()
          }
        }
      },
      changeShow: () => {
        showCategory.value = !showCategory.value
      },
      addCategoryLocal: () => {
        if (!categories.value.find(element => element.key === newCategory.value)) {
          categories.value.push({
            name: newCategory.value,
            key: newCategory.value
          })
        }
      },
      uploadVideo: async (published: boolean) => {
        const videoInfo: Video = {
          ...(videoCreated.value !== null && videoCreated),
          title: title.value,
          author: author.value,
          image: base64.value,
          link: link.value,
          position: position.value,
          company: company.value,
          published: published,
          categories: selectedCategories.value.length === 0 ? [] : [selectedCategories.value[0].key],
          hashtags: hashtags.value
        }
        try {
          if (!flag.value) {
            const response = await CoderplusAPI.createVideo(videoInfo)
            videoCreated.value = response
            flag.value = true
            toast.add({
              severity: 'success',
              summary: 'Success',
              detail: 'Video guardado exitosamente',
              life: 3000
            })
          } else {
            const response = await CoderplusAPI.updateVideoById(videoCreated.value!._id!, videoInfo)
            videoCreated.value = response
            toast.add({
              severity: 'success',
              summary: 'Success',
              detail: 'Video actualizado exitosamente, para crear uno nuevo recargue la página',
              life: 3000
            })
          }
        } catch (error) {
          toast.add({
            summary: ToastSummaries.Error,
            detail: 'Ocurrió un error al cargar el video',
            severity: ToastSeverities.Error,
            life: ToastLife.Default
          })
        }
      }
    }
  }
})
