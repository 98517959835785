import { Video } from '@/models/coderplus/classes/Video'
import { Api } from './api'

class CoderplusApi {
  private readonly baseURL: string

  constructor() {
    this.baseURL = process.env.ACADEMIC_API_ENDPOINT
  }

  public async createVideo(video: Video): Promise<Video> {
    const response = await Api.post<Video>(
      `${this.baseURL}/v1/coder-plus/video`,
      video
    )
    return response
  }

  public async findAllVideos(): Promise<Video[]> {
    const response = await Api.get<Video[]>(
      `${this.baseURL}/v1/coder-plus/video/all`
    )
    return response
  }

  public async findVideoById(videoId: string): Promise<Video> {
    const response = await Api.get<Video>(
      `${this.baseURL}/v1/coder-plus/video/${videoId}`
    )
    return response
  }

  public async updateVideoById(
    videoId: string,
    videoInfo: Video
  ): Promise<Video> {
    const response = await Api.patch<Video>(
      `${this.baseURL}/v1/coder-plus/video/${videoId}`,
      videoInfo
    )
    return response
  }

  public async deleteVideoById(videoId: string): Promise<Video> {
    const response = await Api.del<Video>(
      `${this.baseURL}/v1/coder-plus/video/${videoId}`
    )
    return response
  }
}

export const CoderplusAPI = new CoderplusApi()
